export const environment = {
    production: false,
    HOST: 'http://team.learnt.io',
    WEBCAL: 'webcal://api.team.learnt.io',
    API_HOST: 'https://api.team.learnt.io',
    REFERRAL_URL: "https://team.learnt.io",
    API_SOCKET_URL: 'wss:/api.team.learnt.io/ws',
    COOKIE_DOMAIN: '.team.learnt.io',
    MAPBOX_TOKEN: 'pk.eyJ1IjoidHV0b3JhcHAiLCJhIjoiY2luNjJrYThqMGU4NHZobTRrb3N5dW8ydyJ9.tWCvx9Qix0jRtOpYN695CQ',
    FacebookAppID: '434644221081502',
    GoogleMapsApiKey: 'AIzaSyA1Mhm-yFWa6wicGrf3F3odYRW-ezLGTk8',
    INTERCOM_APP_ID: 'gv9udgbh',
    NATIVEFORMS_LICENSE: 'vj5hlyew',
    SURVEY_DAYS_RECURRENT: 7,
    GOOGLE_API_KEY: "AIzaSyA1Mhm-yFWa6wicGrf3F3odYRW-ezLGTk8",
    GoogleAppID: "958629747774-qdrqn8tbqavme0at22pd1h49020odu8n.apps.googleusercontent.com",
    LinkedInAppID: "78yv35ku1j8yvo",
    TwitterAppID: "60o0ohtNXJJQRojKdPjks4kBQ",
};
