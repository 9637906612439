import { HttpErrorResponse } from '@angular/common/http';
import {ChangeDetectionStrategy, Component, Inject, Input, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Auth, Backend } from '@app/lib/core/auth';
import { SocketEventData, SocketService } from '@app/lib/core/socket';
import { Subject, User } from '@app/models';
import { Plan } from '@app/models/plan';
import { NotificationsService } from '@app/services/notifications';
import { Subscription } from 'rxjs';

@Component({
    selector: 'learnt-package-payment',
    templateUrl: './package-payment.component.html',
    styleUrls: ['./package-payment.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class PackagePaymentComponent implements OnInit{

    public total = 0;
    public subtotal = 0;
    public discount = 0;
    public subject:Subject;
    public planText:string;
    public title:string;
    public selectedPlan: Plan;
    public ccNum: number;
    public me:User;
    public loading: boolean;
    public hours: number;
    public planId: string;
    public paymentSuccesfull = false 

    private subs: Subscription;

    constructor(
        private auth: Auth,
        private backend: Backend,
        private socket: SocketService,
        private notifications: NotificationsService,
        
        @Inject(MAT_DIALOG_DATA) public data:any,
        private dialogRef: MatDialogRef<PackagePaymentComponent>,
        ){
            if(this.data){
                this.total = (this.data?.total) ? this.data?.total : 0 ;
                this.subject = this.data?.subject;
                this.subtotal = (this.data?.subtotal) ? this.data?.subtotal : this.data?.total ;
                this.discount = (this.data?.discount) ? this.data?.discount : 0 ;
                this.title = (this.data?.title) ? this.data?.title : this.data.subject?.package?.title;
                this.hours = this.data?.hours;
                this.selectedPlan = this.data?.selectedPlan;
                this.planId = this.data?.planId;
                if(data.selectedPlan){
                    this.planText = data.selectedPlan.description
                }else{
                    this.planText = `Custom Plan`;    
                }                                
            }                      

        this.auth.getMe().subscribe(
            (me) => { 
                this.me = me
                if(this.me.card?.number)
                    this.ccNum = +this.me.card.number
        })
    }
    
    ngOnInit():void{
        console.log(this.data);
    }

    cancel(){
        this.dialogRef.close()
    }

    public submit(): void {
        this.bookRequest();
    }


    private bookRequest(): void {
        this.loading = true;
        /*this.subs.add(this.socket.on('notification').subscribe(event => {
            const data = event.data as SocketEventData;
            const notifData = _get(data, 'data.notification.data');
            if (_get(notifData, 'lesson.students', []).includes(payload.student) &&
                _get(notifData, 'lesson.tutor') === payload.tutor &&
                _get(notifData, 'lesson.subject') === payload.subject) {
                this.confirmed = true;
            }
            console.log(data);            
        }))*/


        const payload = {
            total: this.total,
            subject: this.subject._id,
            hours: this.hours,
            user: this.me._id,
            planId: this.planId
        }

        this.backend.buyPackage(payload).subscribe(plan => {
            
            this.loading = false;

            console.log('PLAN: ' + JSON.stringify(plan));

            this.close();

            this.notifications.notify("Plan Payment Completed", "");
        }, (error: HttpErrorResponse) => {
            this.loading = false;
            const err = error.error;
            const notify = (t: string, m: string) => this.notifications.notify(t, m, 'close', 10 * 1000);

            let notifyMessage = `Encountered an issue while buying a plan.`;

            if (err.raw === undefined || err.raw.type === undefined) {
                notify('Error', `We couldn't request: ${err.message}.`);
                return;
            }

            switch (<number>err.raw.type) {
                case 0: // invalid user
                    notifyMessage = `Specified user is invalid, please make sure you filled the form correctly and try again later. ${err.raw.message}`;
                    break;
                case 1: // invalid role
                    notifyMessage = `Specified user is pending, please make sure you filled the form correctly and try again later. ${err.raw.message}`;
                    break;
                case 2: // invalid subject
                    notifyMessage = `Specified subject does not exist, please try again later. ${err.raw.message}`;
                    break;
                case 3: // invalid time
                    notifyMessage = `Encountered an issue related to the selected time: ${err.raw.message}.`;
                    break;
                case 4: // database err
                    notifyMessage = `We couldn't store the lesson in the database. ${err.raw.message} `;
                    notifyMessage += `Please try again later. If it happens again, contact us immediately.`;
                    break;
                default:
                    notifyMessage = `Received an unknown error while booking plan.`;
            }

            notify('Couldn\'t book the lesson', notifyMessage);
        });
    }
    
    close(){        
        this.dialogRef.close();
    }



}


