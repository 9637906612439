<div class="tutor" itemscope itemtype="https://schema.org/Person">
                                


    <div class="avatar" itemprop="image" [style.background-image]="'url(' + tutor.avatar + ')'">
        <div *ngIf="tutor.is_test_account" class="test-indicator">TEST ACCOUNT</div>
        <div *ngIf="tutor.online" class="online-status">
            <span> Online </span>
        </div>
    </div>
    <div class="content">
        <div class="name-wrapper">
            <span class="name" itemprop="name">{{tutor.shortName}} </span>
            <div class="rigth-side-rating">{{tutor.tutoring.rate * 1.15|currency:'USD':'symbol'}}/h</div>
        </div>        
<!--        <div class="rating" layout="row" layout-align="start center">            
            <div class="left-side-rating" >
                <div *ngIf="tutor.tutoring.rating > 0">{{tutor.tutoring.rating.toFixed(1)}} &nbsp; 
                    <learnt-rating [rating]="tutor.tutoring.rating"></learnt-rating>
                </div>
            </div>            
            <div class="rigth-side-rating">{{tutor.tutoring.rate|currency:'USD':'symbol'}}/h</div>
        </div>
    -->
        <div class="title-wrapper">
            <span class="title" itemprop="jobTitle">
                {{tutor.tutoring.title}}
            </span>
        </div>

        <div class="location">
            <amp-img src="/assets/search/tutor-card/map-pin.svg" alt="Map Icon"></amp-img>
            <span *ngIf="tutor.location">{{tutor.location.city + ", " + tutor.location.state }}</span>
        </div>
    </div>

    
    <a class="view-profile"  [routerLink]="['/main/tutor/' + tutor._id ]" itemprop="url" target="_blank">
        <!--<mat-icon svgIcon="thunder" color="learnt-green"></mat-icon>-->
        View Profile
    </a>
</div>
